import React, { useState, useEffect } from "react"
import {
  useParams
} from "react-router-dom";

import Link from "@mui/material/Link"
import { Link as RDLink } from "react-router-dom";

import Masonry from "@mui/lab/Masonry"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CardHeader from '@mui/material/CardHeader';
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Chip from '@mui/material/Chip';

import ExpoCard from '../components/ExpoCard'
import PageTitle from '../components/PageTitle'

import QoriWeb from '../QoriWeb'

const chainIds = {
  '0xccca6decfda79726bab099c331bf110691301693': 137  // Polygon Mainnet
  , '0x8467773338935871B73bDA716339620Ef47917C2': 1 // ethereum goerli
}

const qweb3 = new QoriWeb()

const mintPFP = async (contractID) => {
  const chainId = chainIds[contractID]

  const web3 = qweb3.getWeb3()
  const provider = qweb3.getProvider()
  if (provider.networkVersion !== chainId) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(chainId) }]
      });
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if (err.code === 4902) {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: 'Polygon Mainnet',
              chainId: web3.utils.toHex(chainId),
              nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
              rpcUrls: ['https://polygon-rpc.com/']
            }
          ]
        });
      }
    }
  }
  const contract = await qweb3.getContractByAddress(contractID);
  console.log(contract)
  const account = await qweb3.getProvider()
    .request({ method: 'eth_requestAccounts' })
    .then((res) => { return res[0] })

  await contract.methods.mintToken().send({
    from: account,
    value: 95000000000000000000,
    gasLimit: 900000,
    maxFeePerGas: 45000000000,
    maxPriorityFeePerGas: 45000000000
  })
}

const mintNonPFP = async (contractID, tokenId, price) => {
  const chainId = chainIds[contractID]

  const web3 = qweb3.getWeb3()
  const provider = qweb3.getProvider()
  if (provider.networkVersion !== chainId) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(chainId) }]
      });
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if (err.code === 4902) {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: 'Polygon Mainnet',
              chainId: web3.utils.toHex(chainId),
              nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
              rpcUrls: ['https://polygon-rpc.com/']
            }
          ]
        });
      }
    }
  }
  const contract = await qweb3.getContractByAddress(contractID);
  const account = await qweb3.getProvider()
    .request({ method: 'eth_requestAccounts' })
    .then((res) => { return res[0] })
  console.log(price)
  await contract.methods.mintToken(tokenId).send({
    from: account,
    value: 1000000000000000000 * price,
    gasLimit: 900000,
    maxFeePerGas: 45000000000,
    maxPriorityFeePerGas: 45000000000
  })
}

function Collection(props) {
  const { contractID } = useParams()
  const [ nfts, setNFTs ] = useState([])
  const [ name, setName ] = useState('')
  const [ isPFP, setIsPFP ] = useState(false)

  const loadCollection = async () => {
    await qweb3.getCollection(contractID).then(res => {
      console.log(res)
      setNFTs(res.nfts)
      setName(res.meta.name)
      setIsPFP(res.isPFP)
    })
  }

  useEffect(() => {
    loadCollection()
    window.scrollTo(0, 0)
  }, [name])

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ pb: 2 }}
    >
      <Grid
        item
        xs={8}
      >
        <PageTitle text={name} />
        { isPFP &&
          <Box
            textAlign="center"
          >
            { nfts.length > 0 &&
              <Button
                variant="contained"
                sx={{ mb: 2 }}
                onClick={ () => { mintPFP(contractID) } }
              >
                Mint
              </Button>
            }
          </Box>
        }
        <Masonry
          columns={{
            xs: 1
            , sm: 2
          }}
          spacing={{
            xs: 2
            , md: 4
          }}
          sx={{
            pl: 2
            , pr: { md: 6 }
          }}
        >
          {
            nfts.map(o => {
              console.log('ok')
              console.log(o.collectionID)
              return isPFP
                   ? <Link
                       sx={{textDecoration: 'none'}}
                       component={RDLink}
                       to={`/asset/${o.collectionID}/${o.row.token_id}`}
                     >
                     <ExpoCard
                       key={o.id}
                       media={o.image}
                       title={o.name}
                       divider={false}
                     >
                     </ExpoCard>
                   </Link>
                   : <ExpoCard
                       key={o.id}
                       media={o.image}
                       title={o.name}
                       divider={true}
                       price={`${o.row.price} ETH`}
                       mint={() => {                
                         return o.row.isMinted
                              ? <Chip label="Sold"/>
                              : <Button
                                  variant="contained"
                                  onClick={ () => {
                                    mintNonPFP(contractID, o.row.token_id, o.row.price)
                                      .then(async () => {
                                        console.log(name)
                                        await qweb3.sync(name)
                                        loadCollection()
                                      })
                                  }}
                                >
                           Mint
                         </Button>
                       }}
                     >
                   </ExpoCard>
            })
          }
        </Masonry>
      </Grid>
    </Grid>
  )
}

export default Collection
