import React, { useState, useEffect, createRef } from "react"
import logoLarge from './assets/logo.png';
import logoSmall from './assets/logo-small.png';

import { Link as RDLink, useNavigate } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import RLink from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'

import MenuIcon from '@mui/icons-material/Menu';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import DrawerHeader from '@mui/material/Drawer';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

/*

   These constants are here so that the page content gets loaded
   pixel-perfect underneath the navigation bar

 */
const LOGO_HEIGHT_ON_LOAD = 150
const LOGO_HEIGHT_ON_SCROLL = 50
const LOGO_PADDING = 10

/*

   calculate exact logo bounding box height

 */
const LOGO_BOX_SPACING = LOGO_HEIGHT_ON_LOAD

const PAGE_SCROLL_OFFSET = 50
const LOGO_ALT = "Q'Ori Logo"
const LOGO_LINK = '/'

const MOBILE_DRAWER_WIDTH = 115

// custom menu with line detail on hover
const MenuLink = styled(RLink)(({ theme, selected }) => {
  //  console.log(theme)
  return {
    textDecoration: 'none',
    paddingBottom: '5px',
    marginRight: '50px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '0px',
      bottom: '0px',
      width: '20px',
      height: '1px',
      opacity: selected ? 1 : 0,
      transition: 'all 450ms ease 0s',
      backgroundColor: `${theme.palette.primary.light}`,
    },
    '&:hover': {
      color: `${theme.palette.primary.light}`,
      '&:before': {
        opacity: 1
      }
    }
}});

const Link = styled(RDLink)(({ theme }) => {
  return {
    textDecoration: 'none'
  }
})

// helper function for generating pixel dimensions
const px = (n) => n + 'px';

const connWalletText = 'Connect Wallet'
const truncateString = (str) => {
  if(str > connWalletText.length) {
    return str.substr(0, connWalletText.length/2 - 1)
         + '...'
         + str.substr(str.length - (connWalletText.length/2 - 2))
  }
}

const MobileDrawer = (props) => {
  const {
    drawerWidth,
    open,
    handleDrawerClose,
    preventClick,
    allowClick
  } = props
  const [exploreMobileOpen, setExploreMobileOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <Drawer
        SlideProps={{
          onEnter: preventClick
          , onExit: allowClick
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        PaperProps={{
          sx: {
            bgcolor: "primary.contrastText"
            , borderColor: "primary.light"
          }
        }}
        ModalProps={{
          keepMounted: true
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <List>
          <ListItem key="Explore" disablePadding>
            <ListItemButton onClick={() => setExploreMobileOpen(!exploreMobileOpen) }>
              <ListItemText primary="Explore" />
            </ListItemButton>
          </ListItem>
          { exploreMobileOpen &&  (
              <>
                <ListItem key="Piscis" disablePadding>
                  <ListItemButton onClick={(e) => {
                    navigate('/collection/0xccca6decfda79726bab099c331bf110691301693')
                    handleDrawerClose(e)
                    setExploreMobileOpen(false)
                  }}>
                    <ListItemText primary={<Typography sx={{ fontSize: '0.8em' }}>Piscis</Typography>} />
                  </ListItemButton>
                </ListItem>
                <ListItem key="Gihan" disablePadding>
                  <ListItemButton onClick={(e) => {
                    navigate('/collection/0x8467773338935871B73bDA716339620Ef47917C2')
                    handleDrawerClose(e)
                    setExploreMobileOpen(false)
                  }}>
                    <ListItemText primary={<Typography sx={{ fontSize: '0.8em' }}>Gihan</Typography>} />
                  </ListItemButton>
                </ListItem>
              </>
            )}
          <Divider/>
          <ListItem key="Genesis" disablePadding>
            <ListItemButton onClick={(e) => {
              navigate('/genesis')
              handleDrawerClose(e)
              setExploreMobileOpen(false)
            }}>
              <ListItemText primary="Genesis" />
            </ListItemButton>
          </ListItem>
          <Divider/>
          <ListItem key="About Us" disablePadding>
            <ListItemButton>
              <ListItemText primary="About Us" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </ClickAwayListener>
  )
}

const WalletMenu = (props) => {
  const {
    walletMenuAnchorEl
    , walletMenuOpen
    , lockContent
    , unlockContent
  } = props
  return <Menu
           id="wallet-menu"
           disableScrollLock={true}
           anchorEl={walletMenuAnchorEl}
           open={walletMenuOpen}
           PaperProps={{
             elevation: 0,
             sx: {
               overflow: 'visible',
               mt: 1,
               border: 1,
               borderColor: 'primary.light'
             },
           }}
         >
    <MenuItem
      sx={{
        '&:hover': {
          backgroundColor: (theme) => theme.palette.primary.contrastText
        }
      }}
    >
      <Link
        to="/collected"
      >
        My NFTs
      </Link>
    </MenuItem>
    {/* <MenuItem
        onClick={() => {
        locked
        ? unlockContent()
        : lockContent()
        }}
        sx={{
        '&:hover': {
        backgroundColor: (theme) => theme.palette.primary.contrastText
        }
        }}
        >
        { locked
        ? 'Unlock Content'
        : 'Lock Content'
        }
        </MenuItem> */}
  </Menu>
}

const WalletChipMobile = (props) => {
  const {
    walletConnected,
    connectWallet,
    locked,
    lockContent,
    unlockContent,
    mobileDrawerOpen,
    setMobileDrawerOpen,
    clickAllowed
  } = props

  const menuIconRef = createRef()
  const [walletMenuAnchorEl, setWalletMenuAnchorEl] = React.useState(null)
  const walletMenuOpen = Boolean(walletMenuAnchorEl)

  const handleWalletOpen = (target) => {
    if(walletConnected) {
      setWalletMenuAnchorEl(target)
      return
    }
    connectWallet()
  }

  const handleWalletClose = (target) => {
    setWalletMenuAnchorEl(null)
  }

  return (
    <>
      <ClickAwayListener onClickAway={(e) => { handleWalletClose(e.currentTarget) }}>
        <Chip
          avatar={
            <Avatar sx={{
              transform: 'scale3d(1.5, 1.5, 1)'
            }}>
              <AccountBalanceWalletOutlinedIcon
                fontSize="small"
                sx={{
                  transform: 'scale3d(0.75, 0.75, 1)'
                  , color: 'primary.main'
                }}
              />
            </Avatar>
          }
          label={
          !mobileDrawerOpen && (
            <Typography
              onClick={(e) => {
                console.log(clickAllowed)
                if(!clickAllowed) {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              component="span"
              variant="body1"
              sx={{
                pr: 1
                , pl: 1
                , verticalAlign: 'middle'
              }}
            >
              { walletConnected ? truncateString(walletConnected) : 'Connect Wallet' }
            </Typography>
          )
          }
          onDelete={(e) => {
            console.log(e)
            console.log('delete')
            e.stopPropagation()
            e.preventDefault()
            setMobileDrawerOpen(!mobileDrawerOpen)
          }}
          deleteIcon={
            <IconButton>
              <MenuIcon/>
            </IconButton>
          }
          sx={{
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px -4px 0px inset',
            '&:hover': {
              backgroundColor: 'primary.light',
              '.MuiChip-avatarColorPrimary': {
                border: (theme) => `2px solid ${theme.palette.primary.light}`
              },
              '.MuiSvgIcon-root': {
                color: 'primary.light'
              }
            },
            '.MuiChip-avatarColorPrimary': {
              border: (theme) => `2px solid ${theme.palette.primary.main}`
              , backgroundColor: 'primary.contrastText'
            }
          }}
          color="primary"
          onClick={(e) => { console.log(e);
            handleWalletOpen(e.currentTarget) }}
        />
      </ClickAwayListener>
      <WalletMenu {...{
          walletMenuAnchorEl
          , walletMenuOpen
          , locked
          , lockContent
          , unlockContent
        }}/>
    </>
  )
}

const WalletChip = (props) => {
  const {
    walletConnected,
    connectWallet,
    locked,
    lockContent,
    unlockContent,
    mobileDrawerOpen,
    setMobileDrawerOpen,
    clickAllowed
  } = props

  const menuIconRef = createRef()
  const [walletMenuAnchorEl, setWalletMenuAnchorEl] = React.useState(null)
  const walletMenuOpen = Boolean(walletMenuAnchorEl)

  const handleWalletOpen = (target) => {
    if(walletConnected) {
      setWalletMenuAnchorEl(target)
      return
    }
    connectWallet()
  }

  const handleWalletClose = (target) => {
    setWalletMenuAnchorEl(null)
  }

  return (
    <>
      <ClickAwayListener onClickAway={(e) => { handleWalletClose(e.currentTarget) }}>
        <Chip
          avatar={
            <Avatar sx={{
              transform: 'scale3d(1.5, 1.5, 1)'
            }}>
              <AccountBalanceWalletOutlinedIcon
                fontSize="small"
                sx={{
                  transform: 'scale3d(0.75, 0.75, 1)'
                  , color: 'primary.main'
                }}
              />
            </Avatar>
          }
          label={
          !mobileDrawerOpen && (
            <Typography
              onClick={(e) => {
                console.log(clickAllowed)
                if(!clickAllowed) {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              component="span"
              variant="body1"
              sx={{
                pr: 1
                , pl: 1
                , verticalAlign: 'middle'
              }}
            >
              { walletConnected ? truncateString(walletConnected) : 'Connect Wallet' }
            </Typography>
          )
          }
          sx={{
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px -4px 0px inset',
            '&:hover': {
              backgroundColor: 'primary.light',
              '.MuiChip-avatarColorPrimary': {
                border: (theme) => `2px solid ${theme.palette.primary.light}`
              },
              '.MuiSvgIcon-root': {
                color: 'primary.light'
              }
            },
            '.MuiChip-avatarColorPrimary': {
              border: (theme) => `2px solid ${theme.palette.primary.main}`
              , backgroundColor: 'primary.contrastText'
            }
          }}
          color="primary"
          onClick={(e) => { console.log(e);
            handleWalletOpen(e.currentTarget) }}
        />
      </ClickAwayListener>
      <WalletMenu {...{
          walletMenuAnchorEl
          , walletMenuOpen
          , locked
          , lockContent
          , unlockContent
        }}/>
    </>
  )
}

function Navigation(props) {
  const {
    walletConnected,
    connectWallet,
    locked,
    lockContent,
    unlockContent
  } = props

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false)
  const [clickAllowed, setClickAllowed] = useState(true)

  const allowClick = () => { console.log('allow'); setClickAllowed(true) }
  const preventClick = () => {  console.log('prevent'); setClickAllowed(false)  }

  const handleMobileMenuOpen = (e) => {
    setMobileMenuOpen(true)
  }

  const handleMobileMenuClose = (e) => {
    setMobileMenuOpen(false)
  }


  const [exploreMenuAnchorEl, setExploreMenuAnchorEl] = React.useState(null);
  const exploreMenuOpen = Boolean(exploreMenuAnchorEl);

  const handleExploreClick = (e) => {
    setExploreMenuAnchorEl(e.currentTarget)
  }
  const handleExploreClose = () => {
    setExploreMenuAnchorEl(null)
  }

  // state for controlling logo height
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [logoHeight, setLogoHeight] = useState(
    px(isMobile
     ? LOGO_HEIGHT_ON_SCROLL
     : LOGO_HEIGHT_ON_LOAD
    )
  )
  const [logo, setLogo] = useState(
    isMobile
    ? logoSmall
    : logoLarge
  )

  useEffect(() => {
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > PAGE_SCROLL_OFFSET) {
        setLogoHeight(px(LOGO_HEIGHT_ON_SCROLL))
        setLogo(logoSmall)
      } else if(!isMobile) {
        setLogoHeight(px(LOGO_HEIGHT_ON_LOAD))
        setLogo(logoLarge)
      }
    }, [])

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      {/*

          A grid container with two grid items. Use "space-between"
          justifyContent so that one item is pushed to the left and
          the other to the right

          Fix container to the top and set an absurd positive zIndex
          so that the page content flows underneath

        */}
      <Grid
        id="nav"
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: 'fixed'
          , top: 0
          , zIndex: 99
          , background: (theme) => theme.palette.primary.contrastText
          , borderBottom: 1
          , borderColor: 'primary.light'
          , ...(mobileDrawerOpen && {
            width: `calc(100% - ${MOBILE_DRAWER_WIDTH}px)`
          })
        }}
      >

        {/*

            First Grid item is the logo, it will be aligned to the
            left because of the "space-between" justifyContent setting
            above

          */}
        <Grid
          item
          xs={4}
        >
          <Link to={LOGO_LINK}>
            <Box
              sx={{
                height: logoHeight
                , p: px(LOGO_PADDING)
                , transition: 'all 450ms ease 0s',
                // , background: '#666'
              }}
              component="img"
              src={logo}
              alt={LOGO_ALT}
            />
          </Link>
        </Grid>

        {/*

            Second grid item are all the menu items aligned to the
            left because of the "flex-end" justifyContent setting
            below

          */}
        <Grid
          container
          item
          justifyContent="flex-end"
          alignItems="stretch"
          xs={8}
          sx={{
            p: px(LOGO_PADDING)
            , display: {
              xs: 'none',
              md: 'flex'
            }

          }}
        >
          <Grid item sx={{pt: '8px'}}>
            <ClickAwayListener onClickAway={handleExploreClose}>
              <MenuLink
                variant="body1"
                href="#"
                onClick={handleExploreClick}
              >
                Explore
              </MenuLink>
            </ClickAwayListener>
          </Grid>
          {/* <Grid item sx={{pt: '8px'}}>
              <MenuLink
              variant="body1"
              href="#"
              >
              The Muniverse
              </MenuLink>
              </Grid> */}
          <Grid item sx={{pt: '8px'}}>
            <MenuLink
              component={RDLink}
              to="/genesis"
              variant="body1"
            >
              Genesis
            </MenuLink>
          </Grid>
          <Grid item sx={{pt: '8px'}}>
            <MenuLink
              variant="body1"
              href="#"
            >
              About Us
            </MenuLink>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => { window.open('https://twitter.com/qoriproject', '_blank') }}
            >
              <TwitterIcon
                color="primary"
                sx={{
                  '&:hover': {
                    color: 'primary.light'
                    , transform: 'scale3d(1.15, 1.15, 1)'
                  }
                }}
              />
            </IconButton>
          </Grid>
          <Grid item sx={{pr: '20px'}}>
            <IconButton
              onClick={() => { window.open('https://www.instagram.com/qoriproject', '_blank') }}
            >
              <InstagramIcon
                color="primary"
                sx={{
                  '&:hover': {
                    color: 'primary.light'
                    , transform: 'scale3d(1.15, 1.15, 1)'
                  },
                }}
              />
            </IconButton>
          </Grid>
          <Grid item sx={{pt: '4px'}}>
            <WalletChip  {...{
                walletConnected,
                connectWallet,
                locked,
                lockContent,
                unlockContent,
                mobileDrawerOpen,
                setMobileDrawerOpen,
                clickAllowed
              }}/>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            p: px(LOGO_PADDING)
            , display: {
              xs: 'flex',
              md: 'none'
            }

          }}
        >
          <WalletChipMobile  {...{
              walletConnected,
              connectWallet,
              locked,
              lockContent,
              unlockContent,
              mobileDrawerOpen,
              setMobileDrawerOpen,
              clickAllowed
            }}/>
          {/* <IconButton
              onClick={handleMobileMenuOpen}
              >
              <MenuIcon
              color="primary"
              />
              </IconButton> */}
        </Grid>
      </Grid>

      <Menu
        id="explore-menu"
        disableScrollLock={true}
        anchorEl={exploreMenuAnchorEl}
        open={exploreMenuOpen}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1,
            border: 1,
            borderColor: 'primary.light'
          },
        }}
      >
        <MenuItem
          component="span"
          onClick={() => {}}
          sx={{
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.contrastText
            }
          }}
        >
          <Link
            to="/collection/0xccca6decfda79726bab099c331bf110691301693"
          >
            Piscis, El Cantante
          </Link>
        </MenuItem>
        <MenuItem
          component="span"
          onClick={() => {}}
          sx={{
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.contrastText
            }
          }}
        >
          <Link
            to="/collection/0x8467773338935871B73bDA716339620Ef47917C2"
          >
            Gihan Tubbeh
          </Link>
        </MenuItem>
      </Menu>
      <MobileDrawer
        open={mobileDrawerOpen}
        drawerWidth={MOBILE_DRAWER_WIDTH}
        handleDrawerClose={() => { setMobileDrawerOpen(false) }}
        allowClick={allowClick}
        preventClick={preventClick}
      />

      {/*

          This box is here to "push" content down to the visible area
          under the navigation bar

        */}
      <Box
        sx={{
          height: isMobile
                ? px(LOGO_HEIGHT_ON_SCROLL)
                : px(LOGO_HEIGHT_ON_LOAD)
        }}>
      </Box>
    </>
  )
}

export default Navigation;
