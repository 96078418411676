import Typography from "@mui/material/Typography"

const PageTitle = ({text}) => {
  return (
    <Typography
      variant="h1"
      sx={{
        width: '100%',
        textAlign: 'center',
        fontSize: {
          xs: '4em',
          md: '6em'
        },
        mt: 10
      }}
    >
      {text}
    </Typography>
  )
}
export default PageTitle
