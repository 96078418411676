import React, { useState, useEffect } from "react"

import './App.css'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { ThemeProvider, createTheme } from '@mui/material/styles';

import QoriWeb from './QoriWeb'

import Navigation from './Navigation'
import Footer from './Footer'

import Home from './pages/Home'
import Genesis from './pages/Genesis'
import UserNFT from './pages/UserNFT'
import Collected from './pages/Collected'
import Collection from './pages/Collection'

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#582058',
      light: '#C41C7C',
      dark: '#34242C',
      contrastText: '#F9EDFC',
    },
    secondary: {
      main: '#f50057',
    },
  },
  typography: {
    fontFamily: 'Roboto Condensed',
    h3: {
      fontFamily: 'Poppins',
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    h4: {
      lineHeight: '1em',
    },
    h5: {
      fontFamily: 'Roboto Condensed',
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    h1: {
      fontFamily: 'Roboto Condensed',
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontFamily: 'Roboto Condensed',
      letterSpacing: '0.04em',
      lineHeight: '1.2em'
    },
    h6: {
      fontFamily: 'Raleway',
      fontWeight: 600,
    },
  },
})

const qweb3 = new QoriWeb()

function App() {
  // hash ID of the connected web3 wallet
  const [walletID, setWalletID] = useState()
  const requestWalletID = () => {
    qweb3.connectWallet().then(newWalletID => {
      setWalletID(newWalletID)
    })
  }

  useEffect(() => {
    qweb3.checkConnection().then(wid => {
      setWalletID(wid)
    })
  }, [])

  useEffect(() => {
    const provider = qweb3.getProvider()
    if(provider) {
      const listener = (accounts) => {
        const newWalletID = qweb3.checkAccounts(accounts)
        console.log('wallet changed')
        setWalletID(newWalletID)
        lockContent()
      }
      provider.on('accountsChanged', listener)
      return () => {
        provider.removeListener('accountsChanged', listener)
      }
    }
  }, [])


  const _getSig = () => qweb3.getCookie('sig')
  const _getNid = () => qweb3.getCookie('nid')

  const [sig, setSig] = useState(_getSig())
  const [nid, setNid] = useState(_getNid())

  const _setSig = sig => {
    qweb3.setCookie('sig', sig)
    setSig(sig)
  }
  const _setNid = nid => {
    qweb3.setCookie('nid', nid)
    setNid(nid)
  }

  const lockContent = () => {
    console.log('lock content')
    qweb3.deleteCookie('sig')
    qweb3.deleteCookie('nid')
    setSig(undefined)
    setNid(undefined)
  }

  // request signature for connected wallet
  const signUsingWalletID = async () => {
    // TODO: handle wallet disconnected
     qweb3.requestNonceForWallet(walletID).then(async res => {
       const { nonceID, msg } = res
       const { sig } = await qweb3.requestSigForWallet(nonceID, msg, walletID)
       _setSig(sig)
       _setNid(nonceID)
     })
  }

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Navigation
            walletConnected={walletID}
            connectWallet={requestWalletID}
            lockContent={lockContent}
            unlockContent={signUsingWalletID}
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/genesis" element={<Genesis />} />
            <Route path="/collected"
              element={<Collected
                         walletConnected={walletID}
              />}/>
            <Route path="/asset/:contractID/:nftID"
              element={<UserNFT
                         walletID={walletID}
                         sig={sig}
                         nid={nid}
                         unlockContent={signUsingWalletID}
                         lockContent={lockContent}
              />}
            />
            <Route path="/collection/:contractID"
              element={<Collection/>}
            />
          </Routes>
          <Footer/>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
