import React from "react";

import Link from "@mui/material/Link"
import { Link as RDLink } from "react-router-dom";

import PageTitle from './PageTitle'
import ExpoCard from './ExpoCard'

import DecorSuprematist from './DecorSuprematist'
import DecorCircle from './DecorCircle'
import DecorStripes from './DecorStripes'
import DecorDots from './DecorDots'
import Typography from "@mui/material/Typography"

import Grid from "@mui/material/Grid"

import Masonry from "@mui/lab/Masonry"
import Box from '@mui/material/Box';

const decor = [
  <DecorSuprematist/>
  , <DecorCircle/>
  , <DecorStripes/>
  , <DecorDots/>
]

const randomDecor = () => {
  return decor[Math.floor(Math.random() * (decor.length - 1))]
}

const Upcoming = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        sx={{ pb: theme => theme.spacing(5) }}
      >
        <PageTitle
          text="Upcoming"
        />
      </Grid>
      <Grid
        item
        xs={8}
      >
        <Masonry
          columns={{
            xs: 1
            , sm: 2
          }}
          spacing={{
            xs: 2
            , md: 4
          }}
          sx={{
            pl: theme => theme.spacing(2)
          }}
        >
          <Link
            sx={{textDecoration: 'none'}}
            component={RDLink}
            to={`/collection/0x8467773338935871B73bDA716339620Ef47917C2`}
          >
            <ExpoCard
              media='/api/v1/asset/public/web/gihan-tubbeh.jpg'
              title="Memorias de una Noche Imaginada"
              author="by Gihan Tubbeh"
              description="A series of photographs combining emotional and geographical landscapes, where the relationship between humans and nature is under a spell."
              date="2H 2022"
              decor={randomDecor()}
            />
          </Link>
          <ExpoCard
            media='/api/v1/asset/public/web/aldo-chaparro.jpg'
            title="Work of Steel"
            author="by Aldo Chaparro"
            description={<>A special edition release of physical sculptures with an associated NFT for Aldo Chaparro's "Work of Steel" collection. This will be a limited number of special edition pieces and will pave the way for Aldo's art to have associated NFTs with his physical art</>}
            date="2H 2022"
            decor={randomDecor()}
          />
          <ExpoCard
            media='/api/v1/asset/public/web/gods.jpg'
            title="Gods"
            description="A collection of 130 unique NFTs plus benefits package."
            author="by Flavio Caporalli"
            date="2H 2022"
            decor={randomDecor()}
          />
          <ExpoCard
            media='/api/v1/asset/public/web/pumas.jpg'
            title="Pumas"
            description="A collection of 3,282 unique NFTs – digital collectibles plus benefits package."
            author="by Mr. Coco"
            date="2H 2022"
            decor={randomDecor()}
          />
          <ExpoCard
            media='/api/v1/asset/public/web/pollinators.jpg'
            title="Pollinators"
            description="Featuring Jade Rivera, a muralist artist, producing a series of animated shorts."
            author="by Espiritu Studio"
            date="2H 2022"
            decor={randomDecor()}
          />
        </Masonry>
      </Grid>
    </Grid>
  )
}

export default Upcoming
