import Web3 from 'web3'


// import contracts from '@qori/contracts'
const contracts = {
  piscis: {
    network: 'polygon'
    , address: '0xcCCA6DECfDa79726bAb099c331bF110691301693'
  },
  birds: {
    network: 'ethereum'
    , address: '0x6933FD63fa63A80Fe248e451B59c3Bb0ba772AcE'
  }
}

export default class Qori {

  getNetwork(name) {
    return contracts[name].network
  }

  getContractAddress(name) {
    return contracts[name].address
  }

  getOwnerAddress() {
    return '0x8B672ab73d3Ccf2b52535a8ffA205D0136C64569'
  }

  getWeb3() {
    return new Web3(this.getProvider())
  }

  getProvider() {
    return this.buildProvider()
  }

  getContractABI(name) {
    console.log(name)
    console.log(contracts)
    console.log(contracts[name])
    return contracts[name].abi
  }

  getContractInstance(name) {
    const qweb3 = this.getWeb3()
    // console.log(web3.eth)
    // console.log(this.getContractABI().abi)
    return new qweb3.eth.Contract(
      this.getContractABI(name).abi
      , this.getContractAddress(name)
      , {
        from: this.getOwnerAddress()
      }
    )
  }

  getBaseTokenUri(name) {
    return this.getContractInstance(name).methods.baseTokenURI.call()
  }

}
