import React from "react";

import Box from '@mui/material/Box';

const randomAngle = () => {
  return Math.floor(Math.random() * 360)
}

const DecorSuprematist = () => (
  <>
    <Box
      sx={{
        position: 'absolute',
        left: theme => `-${theme.spacing(2)}`,
        width: '180px',
        height: '1px',
        zIndex: -10,
        transform: `rotate(${randomAngle()}deg)`,
        backgroundColor: 'primary.light',
        opacity: '0.3'
      }}
    >
    </Box>
    <Box
      sx={{
        position: 'absolute',
        left: theme => `-${theme.spacing(2)}`,
        width: '150px',
        height: '150px',
        zIndex: -10,
        transform: `rotate(${randomAngle()}deg)`,
        backgroundColor: 'primary.light',
        opacity: '0.1'
      }}
    >
    </Box>
    <Box
      sx={{
        position: 'absolute',
        left: theme => `${theme.spacing(2)}`,
        width: '200px',
        height: '2px',
        zIndex: -10,
        transform: `rotate(${randomAngle()}deg)`,
        backgroundColor: 'primary.light',
        opacity: '0.1'
      }}
    >
    </Box>
  </>
)
export default DecorSuprematist
