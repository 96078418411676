import React from "react"

import Box from '@mui/material/Box'

import { styled } from '@mui/material/styles'

const CarouselImg = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100vw'
  },
  [theme.breakpoints.up('md')]: {
    height: '50vh'
  }
}))

export default CarouselImg
