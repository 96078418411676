import React from "react";

import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

const ExpoCard = ({
  media,
  title,
  author,
  description,
  date,
  decor,
  blur = false,
  divider = true,
  price,
  mint = () => {}
}) => (
  <Card
    sx={{
      border: 1,
      borderColor: 'primary.light',
      boxShadow: 4,
      ...( blur &&
           {
             '&:before': {
               position: 'absolute',
               left: '0px',
               content: '""',
               backgroundImage: `url(${media})`,
               backgroundSize: '50%',
               backgroundPosition: '50% 0%',
               width: '100%',
               height: {
                 xs: '100%',
                 xl: '80%'
               },
               zIndex: -10,
               transform: {
                 xs: 'translate(0%, 5%)'
                 , sm: 'translate(0%, 8%)'
                 , md: 'translate(0%, 10%)'
               },
               filter: {
                 xs: 'blur(40px) opacity(0.3) brightness(1.3)',
                 xl: 'blur(60px) opacity(0.2jk) brightness(1.5)',
               }
             }
           }
      )
    }}
  >
    <CardMedia component="img" image={media} sx={{ transform: 'blur(100px)'}} />
    <CardContent
      sx={{
        backgroundColor: (theme) => theme.palette.primary.contrastText
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontSize: {
            xs: '1.5em'
            , md: '3em'
          }
        }}
      >
        {title}
      </Typography>
      { author &&
        <Typography
          variant="subtitle2"
          sx={{
            pb: (theme) => theme.spacing(2),
            pl: (theme) => theme.spacing(2)
          }}
        >
          {author}
        </Typography>
      }
      { divider &&
        <Divider sx={{ mb: 2, opacity: 1, bgcolor: 'primary.light' }}/>
      }
      { description &&
        <Typography
          variant="body1"
          sx={{
            mt: (theme) => theme.spacing(2)
          }}
        >
          {description}
        </Typography>
      }
      {date &&
       <Chip
         sx={{
           mt: (theme) => theme.spacing(2)
         }}
         label={date}
       />
      }
      {mint()}
      {price &&
       <Chip
         sx={{
           ml: 2
           , mb: 1
           , mt: 1
         }}
         label={price}
       />
      }
    </CardContent>
    {decor}
  </Card>
)

export default ExpoCard
