import Box from '@mui/material/Box';

const DecorCircle = () => (
  <Box
    sx={{
      position: 'absolute',
      right: theme => theme.spacing(2),
      width: '30vw',
      height: '30vw',
      zIndex: -10,
      borderRadius: '50%',
      backgroundColor: 'primary.light',
      opacity: '0.1'
    }}
  >
  </Box>
)

export default DecorCircle
