import React from "react"

import BCarousel from 'react-bootstrap/Carousel'

import { styled } from '@mui/material/styles'

const CarouselHover = styled(BCarousel)(({ theme }) => ({
  '.carousel-control-prev': {
    opacity: 0
  },
  '.carousel-control-next': {
    opacity: 0
  },
  '.carousel-indicators': {
    opacity: 0
  },
  '&:hover': {
    '.carousel-control-prev': {
      opacity: 0.5
    },
    '.carousel-control-next': {
      opacity: 0.5
    },
    '.carousel-indicators': {
      opacity: 0.5
    }
  }
}))

export default CarouselHover
