import React from "react";
import logo from './assets/logo.png';

import { alpha } from '@mui/material/styles'
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const Footer = () => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          bottom: '0px',
          bgcolor: theme => theme.palette.primary.contrastText,
          borderTop: 1,
          borderColor: 'primary.light'
        }}
      >
        <Grid
          item
          xs={4}
        >
          <Box
            component="img"
            src={logo}
            sx={{
              width: '50%',
              paddingTop: theme => theme.spacing(4)
            }}
          />
        </Grid>
        <ThemeProvider
          theme={(theme) => createTheme({
            ...theme,
            typography: {
              ...theme.typography,
              h1: {
                ...theme.typography.body1,
                color: theme.palette.primary.dark,
                fontSize: { xs: '3em', md: '4em'}
              },
              h2: {
                ...theme.typography.body1,
                color: theme.palette.primary.dark,
                fontSize: { xs: '1em', md: '1.5em' },
                lineHeight: '0.2em'
              },
              body1: {
                ...theme.typography.body1,
                color: theme.palette.primary.dark,
                fontSize: { xs: '1.5em', md: '4em' },
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4)
              }
            }
          })
          }
        >
          <Grid
            item
            xs={6}
            sx={{
              pl: {
                xs: '30px',
                md: '100px',
              }
            }}
          >
            {/* <List>
                <ListItem>
                <ListItemText
                primary={
                <Typography
                variant="h1"
                >
                About Us
                </Typography>
                }
                />
                </ListItem>
                </List>
                <List>
                <ListItem>
                <ListItemText
                primary={
                <Typography
                variant="h2"
                >
                Learn More
                </Typography>
                }
                />
                </ListItem>
                </List>
                <List>
                <ListItem>
                <ListItemText
                primary={
                <Typography
                variant="h2"
                >
                Genesis Drop
                </Typography>
                }
                />
                </ListItem>
                </List>
                <List>
                <ListItem>
                <ListItemText
                primary={
                <Typography
                variant="h2"
                >
                The Muniverse
                </Typography>
                }
                />
                </ListItem>
                </List>
                <List>
                <ListItem>
                <ListItemText
                primary={
                <Typography
                variant="h2"
                >
                Roadmap
                </Typography>
                }
                />
                </ListItem>
                </List> */}
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Typography
              variant="body1"
            >
              contact@qoriproject.io
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
          </Grid>
          <Grid
            item
            xs={10}
          >
            <Divider
              sx={{
                bgcolor: 'primary.light'
                , opacity: 1
              }}
            />
          </Grid>
          <Grid
            item
            xs={10}
          >
            <Typography variant="body1">Copyright © Q'Ori | All Rights Reserved 2022</Typography>
          </Grid>
        </ThemeProvider>
      </Grid>

    </>
  )
}

export default Footer
