import React, { useState, useEffect } from "react"

import {
  useParams
} from "react-router-dom";

import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Masonry from "@mui/lab/Masonry"
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"

import UnlockableCard from '../components/UnlockableCard'

import QoriWeb from '../QoriWeb'

const qweb3 = new QoriWeb()

const UserNFT = (props) => {
  const {
    walletID
    , sig
    , nid
    , lockContent
    , unlockContent
  } = props

  const { contractID, nftID } = useParams()
  const [ meta, setMeta ] = useState()
  const [ nftContent, setNFTContent ] = useState([])

  useEffect(() => {
    const url = `/api/v1/asset/public/${contractID.toLowerCase()}/${nftID}`
    fetch(url).then(res => {
      res.json().then(meta => {
        setMeta(meta)
        window.scrollTo(0, 0)
      })
    })
  }, [contractID, nftID])

  useEffect(() => {
    if(contractID && nftID) {
      qweb3.getNFTContent(contractID, nftID)
        .then((content) => {
          setNFTContent(content)
        })
    }
  }, [contractID, nftID])

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        pb: 2
      }}
    >
      <Grid
        item
        xs={8}
      >
        <Box component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Card
            sx={{
              border: 1
              , borderColor: 'primary.light'
              , boxShadow: 4
              , display: 'flex'
              , flexDirection: {
                xs: 'column-reverse'
                , md: 'row'
              }
              , m: 2
            }}
          >
            <Box component="div" sx={{
              display: 'flex'
              , flexDirection: 'column'
              , justifyContent: 'center'
              , width: {
                xs: '100%'
                , md: '50%'
              }
            }}>
              <CardContent>
                <Typography
                  component="div"
                  variant="h3"
                  sx={{
                    textAlign: 'center'
                    , fontSize: {
                      xs: '2.5em'
                    }
                  }}
                >
                  {meta && meta.name}
                </Typography>
                <Divider
                  sx={{
                    bgcolor: 'primary.light'
                    , opacity: 1
                    , mb: 2
                    , ml: 2
                    , mr: 2
                  }}
                />
                <Grid container
                  spacing={4}
                  justifyContent="center">
                  {meta && meta.attributes.map(a => (
                    <Grid item key={a.trait_type}>
                      <Typography
                        variant="h6"
                        align="center"
                      >
                        {a.trait_type}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        align="center"
                      >
                        {a.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Box>
            {
              meta &&
              <CardMedia
                component="img"
                image={meta.image}
                sx={{
                  width: {
                    xs: '100%'
                    , md: '50%'
                  }
                }}
              />
            }
          </Card>
        </Box>
        { nftContent.length > 0 &&
          <>
            <Typography
              variant="h1"
              align="center"
              sx={{
                p: 4
                , fontSize: {
                  xs: '2em'
                }
              }}
            >
              Unlockable Content
            </Typography>
            <Masonry
              columns={{
                xs: 1
                , sm: 2
              }}
              spacing={{
                xs: 2
                , md: 4
              }}
            >
              {
                nftContent.map(o => {
                  return <UnlockableCard
                           key={o.id}
                           media={qweb3.assetPath(o.nft_content_datum.cover_path)}
                           content={qweb3.privateAssetPath(
                             walletID
                             , o.token_id
                             , o.nft_content_datum.file_path
                           )}
                           title={o.nft_content_datum.meta.description}
                           sig={sig}
                           nid={nid}
                           walletID={walletID}
                           lockContent={lockContent}
                           unlockContent={unlockContent}
                  />
                })
              }
            </Masonry>
          </>
        }
      </Grid>
    </Grid>
  )
}
export default UserNFT
