import React from "react"

import 'bootstrap/dist/css/bootstrap.min.css'
import BCarousel from 'react-bootstrap/Carousel'

import { styled } from '@mui/material/styles'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CarouselHover from './CarouselHover'
import CarouselImg from './CarouselImg'

import Link from "@mui/material/Link"
import { Link as RDLink } from "react-router-dom";

// http://localhost:3000/collection/0xccca6decfda79726bab099c331bf110691301693

const CarouselItem = (props) => {
  const {
    mainImage
    , title
    , subtitle1
    , subtitle2
  } = props

  return (
    <Grid
      container
      alignItems="stretch"
      justifyContent="space-evenly"
      sx={{
        width: '100%',
        borderTop: 1,
        borderBottom: 1,
        borderColor: 'primary.light'
      }}
    >
      <Grid
        item
        xs={11}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${mainImage})`,
            backgroundPosition: 'center',
            position: 'absolute',
            left: '-5px',
            zIndex: '-20',
            transform: 'scale(1.5)',
            filter: 'blur(12px) opacity(0.3) brightness(1.2)',
          }}
        >
        </Box>
        <Grid
          container
          item
          direction="row"
          alignItems="stretch"
          justifyContent="center"
          sx={{
            height: { md: '50vh' }
            , width: '100%'
            , pl: {
              xs: '10%'
              , md: '5%'
            }
            , pr: {
              xs: '10%'
              , md: '5%'
            }
            , pt: {
              xs: '15%'
              , md: '0'
            }
          }}
        >
          <Grid item md={6} sx={{p: '5%'}}>
            <Grid
              container
              item
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              sx={{height: '100%', pl: { xl: '55%', md: '0' }}}
            >
              <Grid item>
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{
                    color: 'primary.main'
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: 'primary.main'
                    , fontSize: '1.8em'
                  }}>
                  {subtitle1}
                </Typography>
              </Grid>
              <Grid item>
                <Divider sx={{
                  bgcolor: 'primary.light'
                  , opacity: 1
                  , mt: '1em'
                  , mb: '1em'
                }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: 'primary.main'
                  }}>
                  {subtitle2}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sx={{height: '100%', pb: { xs: '10%', md: '0' }, pt: {xs: '10%', md: '0' }}}>
            <CarouselImg component="img" src={mainImage}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

function CarouselBanner() {
  return (
    <>
      <CarouselHover>
        <BCarousel.Item>
          <Link
            sx={{textDecoration: 'none'}}
            component={RDLink}
            to={'/collection/0x8467773338935871B73bDA716339620Ef47917C2'}
          >
            <CarouselItem
              mainImage="/api/v1/asset/public/web/gihan-tubbeh.jpg"
              title="Gihan Tubbeh"
              subtitle1="Memorias de una Noche Imaginada"
              subtitle2="A series of photographs combining emotional and geographical landscapes, where the relationship between humans and nature is under a spell"
            />
          </Link>
        </BCarousel.Item>
        <BCarousel.Item>
          <Link
            sx={{textDecoration: 'none'}}
            component={RDLink}
            to={'/collection/0xccca6decfda79726bab099c331bf110691301693'}
          >
            <CarouselItem
              mainImage="/api/v1/asset/public/web/piscis.jpg"
              title="Piscis el Cantante"
              subtitle1="Chapter 1"
              subtitle2="Discover the revival of Piscis, el cantante: one of the most controversial, famous and sensual musicians of all time."
            />
          </Link>
        </BCarousel.Item>
        {/* <BCarousel.Item>
            <CarouselItem
            mainImage="/api/v1/asset/public/web/birds.jpg"
            title="Birds testing"
            subtitle1="Testing"
            subtitle2="Another Test"
            />
            </BCarousel.Item>
            <BCarousel.Item>
            <CarouselItem
            mainImage="/api/v1/asset/public/web/muniverse.jpg"
            title="The Muniverse"
            subtitle1="Testing"
            subtitle2="Another Test"
            />
            </BCarousel.Item> */}
      </CarouselHover>
    </>
  )
}
export default CarouselBanner
