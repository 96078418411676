import Box from '@mui/material/Box';

const DecorDots = () => (
  <Box
    sx={{
      position: 'absolute',
      right: '8vw',
      width: '20vw',
      height: '20vw',
      zIndex: -10,
      backgroundSize: '22px 22px',
      transform: 'rotate(30deg)',
      backgroundImage: theme => ''
                            + 'radial-gradient('
                            + theme.palette.primary.light
                            + '2px, transparent 2px)'
    }}
  >
  </Box>
)

export default DecorDots
