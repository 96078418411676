import React, { useState, useEffect } from "react"

import ExpoCard from '../components/ExpoCard'
import PageTitle from '../components/PageTitle'

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"

import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const GenesisCard = ({isHalf = false, media, title, author, description}) => {
  return (
    <Box sx={{
      ...(isHalf && { width: { md: '50%'} })
      , m: 2
    }}>
      <ExpoCard
        media={media}
        title={title}
        author={author}
        description={description}
      />
    </Box>
  )
}

const ArtistCard = ({name, collection, instagram, media, isOdd = false}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Card
      sx={{
        border: 1,
        borderColor: 'primary.light',
        boxShadow: 4,
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        m: 2
      }}
    >
      { (!isOdd || isMobile) &&
        <CardMedia
          component="img"
          image={media}
          sx={{
            width: {
              xs: '100%'
              , md: '50%'
            }
          }}
        />
      }
      <Box sx={{
        display: 'flex'
        , flexDirection: 'column'
        , justifyContent: 'center'
        , width: {
          xs: '100%'
          , md: '50%'
        }
      }}>
        <CardContent sx={{ flexGrow: '1 0 auto' }}>
          <Typography
            component="div"
            variant="h3"
            sx={{
              textAlign: 'center'
              , fontSize: {
                xs: '2em'
              }
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{
              textAlign: 'center'
            }}
          >
            {collection}
          </Typography>
          <Typography
            component="div"
            sx={{
              textAlign: 'center'
            }}
          >
            <IconButton
              component="a"
              href={`https://www.instagram.com/${instagram}/`}
              target="_blank"
            >
              <InstagramIcon
                color="primary"
                sx={{
                  '&:hover': {
                    color: (theme) => theme.palette.primary.light,
                    transform: 'scale3d(1.15, 1.15, 1)'
                  }
                }}
              />
            </IconButton>
          </Typography>
        </CardContent>
      </Box>
      { isOdd && !isMobile &&
        <CardMedia
          component="img"
          image={media}
          sx={{ width: '50%' }}
        />
      }
    </Card>
  )
}

function Genesis() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={8}
      >
        <Box component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <PageTitle
            text="Genesis"
          />
          <Typography
            variant="h3"
            sx={{ textAlign: 'center' }}
          >
            Gods, Snakes and Birds
          </Typography>
          <Divider
            sx={{
              bgcolor: 'primary.light'
              , opacity: 1
              , mb: theme => theme.spacing(2)
              , ml: theme => theme.spacing(2)
              , mr: theme => theme.spacing(2)
            }}
          />
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center'
              , mb: theme => theme.spacing(8)
              , ml: theme => theme.spacing(2)
              , mr: theme => theme.spacing(2)
            }}
          >
            Ancient Cultures from Peru believed in the harmony between gods, humans and nature. Under this paradigm a number of mythological creatures took significant importance amongst these cultures creating illustration and pieces of arts, which now are considered relics. Q’ori worked with Latin American Artists to express their art under this concept
          </Typography>
          <GenesisCard
            media='/api/v1/asset/public/web/gods.jpg'
            title="Gods"
            author="by Flavio Caporalli"
          />
          <GenesisCard
            media='/api/v1/asset/public/web/snakes.jpg'
            title="Snakes"
            author="by Gihan Tubbeh"
            description="A collection of 330 unique animated NFTs plus benefits package"
          />
          <GenesisCard
            media='/api/v1/asset/public/web/birds.jpg'
            title="Birds"
            author="by AFROSKY"
            description="A collection of 130 unique NFTs plus benefits package"
          />
          <Box sx={{
            display: 'flex'
            , flexDirection: {
              xs: 'column'
              , md: 'row'
            }
            , justifyContent: 'center'
          }}>
            <GenesisCard
              media='/api/v1/asset/public/web/goddesses.jpg'
              title="Goddesses"
              author="by Mind of Robot"
              description="A collection of 3,282 unique NFTs – digital collectibles plus benefits package"
              isHalf
            />
            <GenesisCard
              media='/api/v1/asset/public/web/pumas.jpg'
              title="Pumas"
              author="by Mr. Coco"
              description="A collection of 3,282 unique NFTs – digital collectibles plus benefits package"
              isHalf
            />
          </Box>
          <Box
            sx={{
              mb: theme => theme.spacing(2)
            }}
          >
            <PageTitle
              text="Artists"
            />
          </Box>
          <ArtistCard
            name="Flavio Caporalli"
            collection="Gods"
            instagram="maikaguilaandina"
            media="/api/v1/asset/public/web/flavio.jpg"
          />
          <ArtistCard
            name="Peralta"
            collection="Snakes"
            instagram="ngrcorvacho"
            media="/api/v1/asset/public/web/peralta.jpg"
            isOdd={true}
          />
          <ArtistCard
            name="Mind of Robot"
            collection="Goddesses"
            instagram="mindofrobot"
            media="/api/v1/asset/public/web/mindofrobot.jpg"
          />
          <ArtistCard
            name="Afrosky"
            collection="Birds"
            instagram="caporaliflavio"
            media="/api/v1/asset/public/web/marcelo.jpg"
            isOdd={true}
          />
          <ArtistCard
            name="Mr. Coco"
            collection="Pumas"
            instagram="iammrcoco"
            media="/api/v1/asset/public/web/coco.jpg"
          />
        </Box>
        <Box
          sx={{
            mb: theme => theme.spacing(2)
          }}
        >
          <PageTitle
            text="Benefits"
          />
        </Box>

        <Typography
          variant="h5"
        >
          Genesis Pack
        </Typography>
        <Divider
          sx={{
            bgcolor: 'primary.light'
            , opacity: 1
            , mb: theme => theme.spacing(2)
          }}
        />
        <Typography
          variant="body1"
          component="div"
        >
          Owning 1 NFT of each artist will give the holder the following
          benefits:
          <Box component="ul" sx={{ pt: 2 }}>
            <li>
              You will be protected by the ancestral energy of the Inka’s gods
            </li>
            <li>
              You can be part of the curators committee to select new projects and
              content creators
            </li>
            <li>
              You will have early access to galleries and experiences in the
              Muniverse
            </li>
            <li>You will receive early access to merchandizing</li>
            <li>You will have access to all pre-sales of future drops</li>
            <li>You will have all the individual benefits of each of the NFTs</li>
            <li>
              You will have access to additional benefits Q’ORI and the community
              may agree in the future
            </li>
          </Box>
        </Typography>

        <Typography
          variant="h5"
        >
          Hanan Pacha
        </Typography>
        <Divider
          sx={{
            bgcolor: 'primary.light'
            , opacity: 1
            , mb: theme => theme.spacing(2)
          }}
        />
        <Typography
          variant="body1"
          component="div"
        >
          Owning 1 NFT of the Gods or the Birds collections will give the holder
          the following benefits:

          <Box component="ul" sx={{ pt: 2 }}>
            <li>You will have access to all pre-sales of future drops</li>
            <li>You will receive 2 free mints for future drops</li>
            <li>
              1 NFT will count for 6 participating tickets for raffles and
              giveaways
            </li>
            <li>
              1 NFT will count for 6x community votes in relation to new projects,
              content creation, merchandizing, etc
            </li>
            <li>Access to AMA with the artist</li>
            <li>
              You will have access to additional benefits Q’ORI and the community
              may agree in the future
            </li>
          </Box>
        </Typography>

        <Typography
          variant="h5"
        >
          Uku Pacha
        </Typography>
        <Divider
          sx={{
            bgcolor: 'primary.light'
            , opacity: 1
            , mb: theme => theme.spacing(2)
          }}
        />
        <Typography
          variant="body1"
          component="div"
        >
          Owning 1 NFT of the Snakes collections and 1 NFT of the PFPs-digital
          collectibles will give the holder the following benefits:
          <Box component="ul" sx={{ pt: 2 }}>
            <li>You will have access to all pre-sales of future drops</li>
            <li>You will receive 1 free mint for future drops</li>
            <li>
              The NFT combo will count for 3 participating tickets for raffles and
              giveaways
            </li>
            <li>
              The NFT combo will count for 3x community votes in relation to new
              projects, content creation, merchandizing, etc
            </li>
            <li>Access to AMA with the artist</li>
            <li>
              You will have access to additional benefits Q’ORI and the community
              may agree in the future
            </li>
          </Box>
        </Typography>

        <Typography
          variant="h5"
        >
          Kai Pacha
        </Typography>
        <Divider
          sx={{
            bgcolor: 'primary.light'
            , opacity: 1
            , mb: theme => theme.spacing(2)
          }}
        />
        <Typography
          component="div"
          variant="body1"
        >
          Owning 1 NFT of each of the PFPs-digital collectibles (1 Goddesses & 1
          Puma) will give the holder the following benefits:
          <Box component="ul" sx={{ pt: 2 }}>
            <li>You will have access to all pre-sales of future drops</li>
            <li>
              The NFT combo will count for 2 participating tickets for raffles and
              giveaways
            </li>
            <li>
              The NFT combo will count for 2x community votes in relation to new
              projects, content creation, merchandizing, etc
            </li>
            <li>Access to AMA with the artist</li>
            <li>
              You will have access to additional benefits Q’ORI and the community
              may agree in the future
            </li>
          </Box>
        </Typography>
      </Grid>
    </Grid>
  )
}
export default Genesis
