import React, { useState, useEffect } from "react";

import Card from "@mui/material/Card"
import CardHeader from '@mui/material/CardHeader';
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import IconButton from '@mui/material/IconButton';

const UnlockableCard = (props) => {
  const {
    media,
    content,
    title,
    wallet,
    description,
    sig,
    nid,
    lockContent,
    unlockContent,
    date
  } = props
  let contentType

  const [ locked, setLocked ] = useState(true)
  const [ canUnlock, setCanUnlock ] = useState(false)

  useEffect(() => {
    fetch(content, { method: 'HEAD' }).then(res => {
      setCanUnlock(res.status != 403)
      setLocked(res.status != 200)
    })
  }, [wallet, sig, nid])

  if(/\.wav/.test(content)) {
    contentType = 'audio'
  } else if(/\.mp4/.test(content)) {
    contentType = 'video'
  }

  const contentElem = {
    image: (
      <CardMedia component="img" image={content} />
    ),
    audio: (
      <>
        <audio controls>
          <source src={content} type="audio/x-wav"/>
          Your browser does not support the audio element.
        </audio>
      </>
    ),
    video: (
      <>
        <video width="100%" controls>
          <source src={content} type="video/mp4"/>
          Your browser does not support the video element.
        </video>
      </>
    )
  }

  return (
    <Card
      sx={{
        border: 1,
        borderColor: 'primary.light',
        boxShadow: 4
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: 'primary.contrastText'
        }}
        action={
          <IconButton
            disabled={!canUnlock}
            onClick={ locked ? unlockContent : lockContent }
          >
            { locked
              ? <LockIcon/>
              : <LockOpenIcon/>
            }
          </IconButton>
          
        }
      />
      <CardMedia component="img" image={media} sx={{ transform: 'blur(100px)'}} />
      <CardContent
        sx={{
          backgroundColor: 'primary.contrastText'
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: {
              xs: '2em'
              , md: '3em'
            }
          }}
        >
          {title}
        </Typography>
        { description &&
          <Typography
            variant="body1"
            sx={{
              mt: 2
            }}
          >
            {description}
          </Typography>
        }
        {date &&
         <Chip
           sx={{
             mt: 2
           }}
           label={date}
         />
        }
        { !locked && canUnlock && contentType && contentElem[contentType] }
      </CardContent>
    </Card>
  )
}

export default UnlockableCard
