import CarouselBanner from '../components/CarouselBanner'
import Upcoming from '../components/Upcoming'
import Current from '../components/Current'

function Home() {
  return (
    <>
      <CarouselBanner/>
      <Upcoming/>
    </>
  )
}
export default Home
