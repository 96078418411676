import Box from '@mui/material/Box';

const DecorStripes = () => (
  <Box
    sx={{
      overflow: 'visible',
      position: 'absolute',
      left: theme => theme.spacing(2) ,
      width: '300px',
      height: '300px',
      zIndex: -10,
      borderRadius: '50%',
      opacity: '0.5',
      background: theme => ''
                       + 'repeating-linear-gradient('
                       + `-45deg, ${theme.palette.primary.light}`
                       + `,${theme.palette.primary.light} 2px,`
                       + '#FFFFFF00 2px, #FFFFFF00 12px )'
    }}
  >
  </Box>
)

export default DecorStripes
