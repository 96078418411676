import Qori from './Qori'

const contracts = {
  Piscis: {
    network: 'polygon'
    , address: '0xcCCA6DECfDa79726bAb099c331bF110691301693'.toLowerCase()
    , abiFile: 'Piscis.json'
  },
  /* birds: {
   *   network: 'ethereum'
   *   , address: '0x6933FD63fa63A80Fe248e451B59c3Bb0ba772AcE'
   *   , abiFile: 'Birds.json'
   * }, */
  Gihan: {
    network: 'ethereum'
    , address: '0x8467773338935871B73bDA716339620Ef47917C2'.toLowerCase()
    , abiFile: 'Gihan.json'
  }
}

const cIndex = {}

for(const id of Object.keys(contracts)) {
  const c = contracts[id]
  cIndex[c.address.toLowerCase()] = { ...c, id }
}


export default class QoriWeb extends Qori {
  buildProvider() {
    return window.ethereum
  }
  setCookie(cname, cvalue, exdays = 1) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        const cookie = c.substring(name.length, c.length);
        return cookie
      }
    }
    return "";
  }

  deleteCookie(cname) {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  checkConnection() {
    const provider = this.getProvider()
    if(!provider)
      throw new Error('Provider not defined')
    return provider
      .request({ method: 'eth_accounts' })
      .then(accounts => {
        return this.checkAccounts(accounts)
      })
  }

  connectWallet() {
    const provider = this.getProvider()
    if(!provider)
      throw new Error('Provider not defined')
    return provider
      .request({ method: 'eth_requestAccounts' })
      .then(accounts => {
        return this.checkAccounts(accounts)
      })
  }

  async getNFTs(account) {
    const web3 = this.getWeb3()
    const ret = await (await fetch(`/api/v1/wallet/${account}`)).json()
    const proms = []
    for(const nft of ret.ownedNfts) {

      const { id, network } = cIndex[nft.contract.address.toLowerCase()]
      proms.push(fetch(`/api/v1/asset/public/${id.toLowerCase()}/${nft.tokenId}`)
        .then(async ret => await ret.json().then( json => {
          return {
            ...json
            , network
            , row: nft
            , collectionID: id
            , id: id + '-' + JSON.stringify(nft.tokenId)
          }
        })
      ))
    }
    return Promise.all(proms)
  }

  async getCollection(contractID) {
    const web3 = this.getWeb3()
    const res = await (await fetch(`/api/v1/collection/${contractID}`)).json()
    const proms = []
    for(const nft of res.nfts) {
      const [ name, network ] = [ res.meta.name, 'polygon' ]
      proms.push(fetch(`/api/v1/asset/public/${name.toLowerCase()}/${nft.tokenId}`)
        .then(async ret => await ret.json().then( json => {
          return {
            ...json
            , network
            , row: nft
            , collectionID: contractID
            , id: name + '-' + JSON.stringify(nft.tokenId)
          }
        })
      ))
    }
    return { meta: res.meta, nfts: await Promise.all(proms), isPFP: res.isPFP }
  }

  checkAccounts(accounts) {
    return accounts > 0 ? accounts[0] : false
  }

  async requestSigForWalletID(wallet) {
    return this.signSession(wallet).then(ret => {
      console.log(ret)
    })
  }
  
  async signSession(account) {
    const url = '/api/v1/session'
    const session = await (await fetch(`${url}?wallet=${account}`)).json()
    const signature = await this.getWeb3().eth.personal.sign(session.message, account)
    const body = {
      ...session,
      signature
    }
    return (await fetch(url, {
      method: 'PUT'
      , headers: {
        'Content-Type': 'application/json',
      }
      , body: JSON.stringify(body)
    })).json()
  }

  async getNFTContent(contractName, tokenId) {
    const url = `/api/v1/collection/${contractName}/${tokenId}`
    return (await fetch(url))
      .json()
  }

  async requestCheckSigForWalletID(sig, walletID) {
    return this.checkSession(walletID).then(ret => {
      console.log(ret)
    })
  }
  
  async checkSession(wallet) {
    return (await fetch(`/api/v1/session?wallet=${wallet}`))
      .json()
  }

  async endSession() {
    return (await fetch('/api/v1/session', {
      method: 'DELETE'
    })).json()
  }

  async getContractByAddress(address) {
    const meta = cIndex[address.toLowerCase()]
    const abi = await fetch(`/api/v1/contract/abi/${meta.abiFile}`)
    const abiJSON = await abi.json()
    const web3 = this.getWeb3()
    return new web3.eth.Contract(
      abiJSON.abi
      , meta.address
      , {
        gasLimit: "1000000"
      }
    )
  }

  async getBalanceForContract(contractID, walletID) {
    return await fetch(`/api/v1/contract/${contractID}/balance/${walletID}`)
  }

  assetPath(path) {
    return '/api/v1/asset/public' + path
  }

  privateAssetPath(wallet, tokenID, path) {
    return '/api/v1/asset/private' + path + `?wallet=${wallet}&tokenID=${tokenID}`
  }

  async sync(contractName) {
    return (await fetch(`/api/v1/sync?contract=${contractName}`))
  }

  async requestNonceForWallet(walletID) {
    return (await fetch(`/api/v1/nonce`, {
      method: 'POST'
      , headers: {
        'Content-Type': 'application/json'
      }
      , body: JSON.stringify({ walletID })
    })).json()
  }

  async requestSigForWallet(nonceID, msg, walletID) {
    console.log(walletID)
    const sig = await this.getWeb3().eth.personal.sign(msg, walletID)
    return { sig }
  }

}
