import React, { useState, useEffect } from "react"

import Link from "@mui/material/Link"
import { Link as RDLink } from "react-router-dom";

import Masonry from "@mui/lab/Masonry"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CardHeader from '@mui/material/CardHeader';
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import ExpoCard from '../components/ExpoCard'
import CarouselBanner from '../components/CarouselBanner'

import QoriWeb from '../QoriWeb'

const qweb3 = new QoriWeb()

function Collected(props) {
  const { walletConnected } = props
  const [ nfts, setNFTs ] = useState([])

  useEffect(() => {
    if(walletConnected) {
      qweb3.getNFTs(walletConnected).then(res => {
        console.log(res)
        setNFTs(res)
      })
    }
  }, [walletConnected])

  return (
    <>
      <CarouselBanner/>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ pb: 2 }}
      >
        <Grid
          item
          xs={8}
        >
          <Typography
            variant="h1"
            align="center"
            sx={{ p: 4 }}
          >
            My NFTs
          </Typography>
          <Masonry
            columns={{
              xs: 1
              , sm: 2
            }}
            spacing={{
              xs: 2
              , md: 4
            }}
            sx={{
              pl: 2
              , pr: 6
            }}
          >
            {
              nfts.map(o => {
                return <Link
                         sx={{textDecoration: 'none'}}
                         component={RDLink}
                         to={`/asset/${o.collectionID}/${o.row.tokenId}`}
                       >
                  <ExpoCard
                    key={o.id}
                    media={o.image}
                    title={o.name}
                    divider={false}
                  >
                  </ExpoCard>
                </Link>
              })
            }
          </Masonry>
        </Grid>
      </Grid>
    </>
  )
}

export default Collected
